<template>
  <div class="container690">
    <iq-card class="other_donate">
      <h3>Select Organization</h3>
      <el-autocomplete class="w-100" popper-class="my-autocomplete" v-model="name" :fetch-suggestions="querySearchOrg"
        placeholder="Search organization" @select="handleSelectOrg" :debounce="600" @blur="autoBlur" clearable>
        <i class="el-icon-arrow-down el-input__icon" slot="suffix"> </i>
        <template slot-scope="{ item }">
          <div class="flex">
            <div style="line-height:32px">{{ item.name }}</div>
          </div>
        </template>
      </el-autocomplete>
      <div style="margin-top: 20px;" v-if="organization_id">
        <span>You have currently selected :<a>{{orgName}}</a></span>
        <p>{{orgDesc}}</p>
      </div>

    </iq-card>
    <SelectAmount @getAmountFunc="getAmount" />
    <InformationForm ref="inForm" @getInform="getInform" />
    <Pay @donate="donate" />
  </div>
</template>

<script>
  import SelectAmount from './components/SelectAmount'
  import InformationForm from './components/InformationForm'
  import Pay from './components/Pay'
  export default {
    data() {
      return {
        name: "",
        orgDesc: "",
        orgName: "",
        organization_id: "",
        amount: "",
        donated_type: 3,
        form: {},
      };
    },


    components: { SelectAmount, InformationForm, Pay },

    created() { },

    methods: {
      // 搜索其它组织
      async querySearchOrg(keyword, cb) {
        let res = await this.$http.getOtherOrganizationList({ name: keyword });
        if (res.status == 200) {
          let userList = res.data;
          cb(userList);
        }
      },

      // 选择其它组织
      handleSelectOrg(item) {
        this.name = item.name
        this.orgName = item.name
        this.orgDesc = item.introduction
        this.organization_id = item.id
      },

      // 选择其它组织失焦
      autoBlur() {
        if (!this.organization_id) this.name = ""
      },

      getInform(form) {
        // console.log('form', form);
        this.form = form;
      },

      getAddressParams(log) {
        this.form.country_id = log.countryid;
        this.form.region_id = log.regionid;
      },

      getAmount(val) {
        this.amount = val;
      },

      donate(amount) {
        if (!this.amount) return this.$message.warning('Please select or fill in the amount')
        let userInfo = JSON.parse(localStorage.getItem("userInfo"));
        this.$http
          .donate({
            user_id: userInfo.user_id,
            donated_type: this.donated_type,
            donation_amount: this.amount,
            amount,
            donor_organization_id: this.organization_id,
            donor_first_name: this.form.first_name,
            donor_last_name: this.form.last_name,
            donor_email: this.form.email,
            donor_country_id: this.form.country_id,
            // donor_organization_id: userInfo.organization_id,
            // is_pay_all: 1,
          })
          .then((res) => {
            if (res.status == 200) {
              if (res.data.is_jump == 1) {
                this.$router.push({
                  path: `/PayMoney`,
                  query: {
                    model_type: 8,
                    id: res.data.order_id,
                  },
                });
              }
            }
          });
      },
    }
  }

</script>
<style scoped lang="less">
  .other_donate {
    padding: 20px;

    h3 {
      font-size: 16px;
      color: #333;
      font-weight: 500;
      margin-bottom: 26px;
    }
  }
</style>